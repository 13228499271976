<template>
  <div id="app" class="container-fluid">
    <Header></Header>
    <div class="row align-items-center justify-content-center">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue';

export default {
  name: 'App',
  components: {
    Header
  }
}
</script>
