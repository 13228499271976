import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Vuelidate from 'vuelidate'

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import Searcher from './components/Searcher.vue';
import Artist from './components/Artist.vue';

Vue.config.productionTip = false

Vue.use(VueRouter);
Vue.use(Vuelidate);

const routes = [
  { path: '/', component: Searcher },
  { path: '/artist', component: Artist },
]

const router = new VueRouter({
  routes,
  mode: 'history',
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
