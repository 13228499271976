<template>
  <nav id="header" class="navbar d-block container-fluid">
    
    <div class="row align-items-center justify-content-center">
        <div class="col-4">
            <a class="navbar-brand" href="/">Groopy.cat</a>
        </div>
        <div class="col-8">
        
            <form>
                <div class="input-group mb-3 mt-3">
                    <input type="text" class="form-control" id="search-input" v-model="search.query" v-on:keydown.enter.prevent="goToLink(search.query)" aria-describedby="search-button">
                    <div class="input-group-append">
                        <button type="button" class="btn btn-primary btn-sm-block" @click="goToLink(search.query);">Submit</button>
                        <!-- <button class="btn btn-primary" type="submit" id="search-button"><i class="fas fa-search"></i></button> -->
                    </div>
                    
                </div>    
            </form>
        </div>
    </div>
    
  </nav>
</template>

<script>

export default {
  name: 'Header',
  data(){
    return {
        search: {
            query: '',
        }
    }
  },
  methods: {
      goToLink(data){
        window.location.href = '/?q=' + data;
    }
  }
}
</script>

