<template>
    <div id="artist" class="col-11">
        <div class="row align-items-center justify-content-center">
            <div id="result" class="col-12">
                <ul class="list-unstyled">
                    <li class="d-inline-block float-start" :data-id="allData.id">
                        <h1>{{ allData.name }}</h1>
                        <img :src="allData.picture_big">
                    </li>
                </ul>
            </div>

            <div id="similar" class="col-12 mt-5">
                <h2>Similar artist</h2>
                <ul class="list-unstyled">
                    <li class="d-inline-block float-start" v-for="currency in similarData.data" v-bind:key="currency" @click="goToLink(currency.id);" :data-id="currency.id">
                        <img :src="currency.picture_big">
                        <h2>{{ currency.name }}</h2>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';

export default {
  name: 'artist',
  data(){
    return {
        submitted: false,
        allData: '',
        similarData: '',
        search: {
            query: '',
        }
    }
  },
  mounted: function () {
    window.scrollTo( 0, 0 );
    
    const dataUrl = this.$route.query.art;
    console.log(dataUrl);

    let url = 'https://api.deezer.com/artist/'+ dataUrl ;

    axios
        .get(url)
        .then(response => (this.allData = response.data))
        .catch(function (error) {
            console.log(error);
        }); 

    let urlSimilar = 'https://api.deezer.com/artist/'+ dataUrl + '/related';

    axios
        .get(urlSimilar)
        .then(response => (this.similarData = response.data))
        .catch(function (error) {
            console.log(error);
        }); 
    
  },
  methods: {
    
    goToLink(data){
        window.location.href = '/artist?art=' + data;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
