<template>
  <div id="searcher" class="col-11">
    
    <!-- Form LastFm -->
    <!-- <form class="d-none">
        <div class="mb-3 text-start">
            <label for="search-input" class="form-label">Search artist</label>
            <input type="text" class="form-control" id="search-input" v-model="search.query" v-on:keydown.enter.prevent="submitQuery()">
        </div>
        <button type="button" class="btn btn-primary" @click="submitQuery();">Submit</button>
    </form>
    <div id="result" v-if="submitted == true" class="d-none">
        <ul class="list-unstyled">
            <li v-for="currency in allData.data.results.artistmatches.artist" v-bind:key="currency">
                <h2>{{ currency.name }}</h2>
                <h3>{{ currency.mbid }}</h3>
                <div v-for="image in currency.image" v-bind:key="image">
                    <img v-if="image.size == 'large'" :src="image['#text']">
                </div>

            </li>
        </ul>    
    </div> -->
    
    <!-- END Form LastFm -->
    
    <div id="result" v-if="submitted == true">
        <ul class="list-unstyled">
            <!-- {{ allData }} -->
            <li class="d-inline-block float-start" v-for="currency in allData" v-bind:key="currency" @click="goToLink(currency.id);" :data-id="currency.id">
                <img :src="currency.picture_big">
                <h2>{{ currency.name }}</h2>
            </li>
        </ul>
    </div>
    <div id="result" v-else >
        <h1>Find Music Artist</h1>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  name: 'searcher',
  data(){
    return {
        submitted: false,
        allData: '',
        search: {
            query: '',
        }
    }
  },
  mounted: function () {
    window.scrollTo( 0, 0 );

    const dataUrl = this.$route.query.q;
    console.log(dataUrl);

    if(dataUrl){
        this.submitted = true;
        let url = 'https://api.deezer.com/search/artist?q='+ dataUrl ;
        let config = {
            headers: {
                
            }
          }
        axios
            .get(url, config)
            .then(response => (this.allData = response.data.data))
            .catch(function (error) {
                console.log(error);
            });     
                
    }else{
        
        // let url = 'https://api.deezer.com/editorial' ;
            
        // axios
        //     .get(url)
        //     .then(response => (this.allData = response.data.data))
        //     .catch(function (error) {
        //         console.log(error);
        //     });
        return false;
    }
    

  },
  methods: {
    // submitQuery(){
    //     this.submitted = true;

    //     if(this.search.query){
    //         let url = 'https://ws.audioscrobbler.com/2.0/?method=artist.search&artist='+ this.search.query +'&api_key=9b37fb2e604475e10f3da1e5aca50811&format=json&limit=50';
    //         const headers = {
    //         "Access-Control-Allow-Headers" : "Content-Type",
    //         "Access-Control-Allow-Origin": "https://www.example.com",
    //         "Access-Control-Allow-Methods": "OPTIONS,POST,GET"
    //     }
    //         axios
    //             .get(url, headers)
    //             .then(response => (this.allData = response))
    //             .catch(function (error) {
    //                 console.log(error);
    //             });   
                
    //     }else{
    //         return false;
    //     }

    // },
    // submitQueryDeezer(){
    //     this.submitted = true;

    //     if(this.search.query){
    //         let config = {
    //             headers: {
    //                 "Access-Control-Allow-Origin": "*",
    //                 "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    //                 "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
    //                 "Content-Type" : "application/json"
    //             }
    //         }
    //         let url = 'https://api.deezer.com/search/artist?q='+ this.search.query ;
            
    //         axios
    //             .get(url, headers)
                
    //             .then(response => (this.allData = response.data.data))
    //             .catch(function (error) {
    //                 console.log(error);
    //             });   
                
    //     }else{
    //         return false;
    //     }

    // },
    goToLink(data){
        window.location.href = '/artist?art=' + data;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
